@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Libre Baskerville", system-ui, sans-serif;
  }
}

/*@media only screen and (max-width: 768px) {
  body {
    padding-left: 5px;
    padding-right: 5px;
  }
}*/

/* In your CSS file (e.g., styles.css) */
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap');

App {
    font-family: 'Libre Baskerville', serif;
}

About {
  font-family: 'Libre Baskerville', serif;
}

